import {
  faBan,
  faCamera,
  faChevronRight,
  faClose,
  faCoins,
  faFlag,
  faGift,
  faPaperPlane,
  faQuoteRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";
import {
  chatChannelsInformations_channels,
  membersInformations_members,
} from "../../Api/types";
import {
  api_postValueOrErrorMessage,
} from "../../Api/util";
import angel from "../../Component/Filters/svg/angel.svg";
import cutie from "../../Component/Filters/svg/cutie.svg";
import { displayPopin } from "../../Hook/popins";
import useUser, { User } from "../../Hook/user";
import close from "../../Popins/EphemeViewer/icons/close.svg";
import Avatar from "../Avatar";
import Button from "../Input/Button";
import Loader from "../Loader";
import Pseudonym from "../Member/Pseudonym";
import GiftBar from "./GiftBar";
import ContentMessage from "./MessagesTypes/ContentMessage";
import ErrorMessage from "./MessagesTypes/ErrorMessage";
import GiftMessage from "./MessagesTypes/GiftMessage";
import GuestMessage from "./MessagesTypes/GuestMessage";
import SimulatedGift from "./MessagesTypes/SimulatedGift";
import SimulatedToken from "./MessagesTypes/SimulatedToken";
import TextMessage from "./MessagesTypes/TextMessage";
import TipMessage from "./MessagesTypes/TipMessage";
import SubError from "./SubError";
import TokenBar from "./TokenBar";
import angelIMG from "./angel.png";
import {
  appendMessage,
  createXHRUploadRequest,
  formatHistory,
  sockedContentMessage,
  sockedGiftMessage,
  sockedTextMessage,
  sockedTipMessage,
  socketMessage,
} from "./utils";
import { reduceImageSize } from "../../utils";
import FileDropArea from "../Droppable";
import UploadingBar from "./ChatComponents/UploadingBar";

type CItem = {
  channel?: chatChannelsInformations_channels;
  home?: boolean;
  closeChat: () => any;
  fullPage?: boolean;
};
function ChatItem({ channel, home, closeChat, fullPage }: CItem) {
  const user = useUser() as User;
  const datasetRef = useRef<any>();
  const [interval, setCInterval] = useState<any>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [messages, setMessages] = useState<any>([]);
  const [xhr, setXHR] = useState<any>(null);
  const [guestErrorMessage, setGuestErrorMessage] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const [subError, setSubscriptionError] = useState<any>(false);
  const [text, setText] = useState<any>("");
  const [dialog, setDialog] = useState<null | membersInformations_members>(
    null,
  );
  const [openSocket, setSocket] = useState<any>(null);
  const navigate = useNavigate();
  const [simulated, setSimulated] = useState<any>();
  const [tokenSimulated, setTokenSimulated] = useState<any>();
  const [openedAddMenu, setOpenedAddMenu] = useState(false);
  const [openedGiftMenu, setOpenedGiftMenu] = useState(
    channel?.defaultOpenGift,
  );
  const [openedTokenMenu, setOpenedTokenMenu] = useState(false);
  const intl = useIntl();
  useEffect(() => {
    if (!channel || !user) return;
    const socket = require("socket.io-client")(
      channel?.server as string,
      {
        query:
          "session_id=" +
          user?.informations?.session_id +
          "&channel_id=" +
          channel?.id,
      } as any,
    );
    setMessages([]);
    if (interval) clearInterval(interval);
    socket.on("error", (err: any) => console.log(err));
    socket.on("history", (history: any) => {
      formatHistory(JSON.parse(history), setMessages);
    });

    setCInterval(
      setInterval(() => {
        socket.emit("online");
      }, 10000),
    );
    setSocket(socket);

    (window as any).triggerErrorMessage = (er: string) => {
      setError(er);
    };

    return () => {
      socket.close();
    };
  }, [channel]);

  useEffect(() => {
    if (!openSocket) return;
    openSocket.once("message", (message: any) => {
      if (
        message.author === user!.informations!.id &&
        channel?.nb_remaining_messages
      ) {
        channel!.nb_remaining_messages! -= 1;
        user.informations!.nb_message_left = channel.nb_remaining_messages;
      }
      appendMessage(message, messages, setMessages);
      channel!.last_message = message;
      window.dispatchEvent(new CustomEvent("RefreshMessageDesktop"));
    });
    if (openSocket.listeners("messageError").length === 0)
      openSocket.on("messageError", (error: any) => {
        if (
          [
            "public_chat_subscription_required",
            "not_enough_tokens",
            "paid_message_not_enough_tokens",
            "paid_website_subscription_needed",
            "to_certified_subscription_needed",
          ].includes(error)
        )
          return setSubscriptionError(error);
        setError(error);
      });
    if (openSocket.listeners("historyMore").length === 0)
      openSocket.on("historyMore", (history: any) => null);
    document
      .getElementById("scroller-bottom-" + channel?.id)
      ?.scrollBy({ top: 100000 });
  }, [openSocket, messages]);

  const sendMessage = async () => {
    if (user.informations?.type === "guest") {
      return setGuestErrorMessage(true);
    }
    if (channel?.nb_remaining_messages === 0)
      return setSubscriptionError("paid_message_not_enough_tokens");
    let message = text.replace(/<[^>]*>?/gm, "");
    openSocket.emit("sendMessage", message);
    setText("");
  };

  const handleReported = () => {
    if (channel?.type === "private") {
      if (closeChat) closeChat();
      else navigate(-1);
    }
  };

  const sendFile = (file: File) => {
    const fd = new FormData();
    fd.append("channel", channel!.id);
    reduceImageSize(file, 1500, 1500).then((blob) => {
      fd.append(
        "content",
        blob as any,
        blob.type.startsWith("video")
          ? (blob as File).name
          : "content." + blob.type.split("/")[1],
      );
      setXHR(
        createXHRUploadRequest(fd, (done) => {
          let error = done.error_message;
          setXHR(null);
          if (error) {
            if (
              [
                "public_chat_subscription_required",
                "not_enough_tokens",
                "paid_message_not_enough_tokens",
                "paid_website_subscription_needed",
                "to_certified_subscription_needed",
              ].includes(error)
            )
              return setSubscriptionError(error);
            setError(error);
          }
        }),
      );
    });
  };

  return (
    <FileDropArea registerFile={sendFile}>
      <div
        className={
          "flex-1 flex h-full text-white flex-col relative justify-center bg-chatbg" +
          (home
            ? " w-chat h-chat max-h-chat-max !pt-0 border-2 overflow-hidden border-b-0 border-border 1px rounded-t-xl"
            : " max-h-[90vh] !max-h-[100dvh]")
        }
      >
        {!fullPage && (
          <div className=" h-16 w-full bg-chatheader flex items-center px-4 fixed lg:relative top-0 z-10">
            <div
              className="absolute top-1/2 -translate-y-1/2 left-6"
              onClick={(event) => {
                event.stopPropagation();
                closeChat()
              }}
            >
              <img src={close} alt="close chat" />
            </div>
            <div className="text-center w-full">
              {channel?.type === "public" && (
                <div>
                  <FormattedMessage
                    id={`chatlist__public_chat__${channel.id}`}
                  />
                </div>
              )}
              {channel?.type === "private" && (<>
                <div className="flex justify-center items-center gap-2"
                >
                  <div className="rounded-full overflow-hidden h-10 w-10" onClick={() => navigate("/member/" + channel.otherMember.id)}>
                    <Avatar member={channel?.otherMember} />
                  </div>
                  <div className="flex flex-col">
                    <div className="flex items-center gap-1">
                      <div onClick={() => navigate("/member/" + channel.otherMember.id)}><Pseudonym online member={channel?.otherMember} /></div>
                      {channel!.otherMember.is_angel && (
                        <img src={angel} alt="angel" className="text-md" />
                      )}
                      {channel!.otherMember.is_cutie && (
                        <img src={cutie} alt="cutie" className="h-4" />
                      )}
                    </div>
                    <div>
                      <div className="flex  items-center gap-2 text-xs text-label">
                        <div><FormattedMessage id={"age_range__value_label__" + channel.otherMember!.age_range!} /></div>
                        {channel.otherMember.cityInformations && <>
                          <div className="w-1 h-1 bg-label rounded-full"></div>
                          <div>{channel.otherMember.cityInformations.name}{channel.otherMember.distance && <span> · {channel.otherMember.distance}km</span>}</div>
                        </>}
                      </div>
                    </div>
                  </div>
                </div>

              </>
              )}
            </div>
          </div>
        )}
        {dialog && (
          <div className="flex items-center py-8 flex-col gap-4 fixed lg:absolute w-full left-0 right-0 z-40 bottom-0 bg-popinbg rounded-xl p-6">
            <div
              onClick={() => {
                setDialog(null);
              }}
              className="absolute top-3 right-4 cursor-pointer text-tabs"
            >
              <FontAwesomeIcon icon={faClose} />
            </div>

            <div className="rounded-full overflow-hidden w-20">
              <Avatar member={dialog} />
            </div>
            <div>
              <Pseudonym member={dialog} />
            </div>
            <Button
              color="primary"
              appearance="full"
              fillSpace
              className={
                "!rounded-xl bg-unactive flex justify-between !py-4 h-auto"
              }
              onClick={() => {
                api_postValueOrErrorMessage(
                  dialog.is_blocking_user ? "members/unblock" : "members/block",
                  { id: dialog.id },
                );
                dialog.is_blocking_user = !dialog.is_blocking_user;
                if (dialog.is_blocking_user) handleReported();
                setDialog(null);
              }}
            >
              <FontAwesomeIcon className="text-primary h-4" icon={faBan} />
              <FormattedMessage
                id={
                  dialog.is_blocking_user
                    ? "member_button__unblock"
                    : "member_button__block"
                }
              />
              <FontAwesomeIcon
                className="text-primary h-4"
                icon={faChevronRight}
              />
            </Button>

            <Button
              appearance="full"
              fillSpace
              className={
                "!rounded-xl bg-unactive flex justify-between !py-4 h-auto"
              }
              color="unset"
              onClick={() => {
                setText(text + `@${dialog.pseudonym}`);
                setDialog(null);
              }}
            >
              <FontAwesomeIcon
                className="text-primary h-4"
                icon={faQuoteRight}
              />
              <FormattedMessage id="chatbutton__tag" />
              <FontAwesomeIcon
                className="text-primary h-4"
                icon={faChevronRight}
              />
            </Button>
            <Button
              appearance="full"
              fillSpace
              className={
                "!rounded-xl bg-unactive flex justify-between !py-4 h-auto"
              }
              color="unset"
              onClick={() => {
                displayPopin()("chatreport", {
                  member: dialog,
                  channel: channel!.id,
                  cb: handleReported,
                });
                setDialog(null);
              }}
            >
              <FontAwesomeIcon className="text-primary h-4" icon={faFlag} />
              <FormattedMessage id="chatbutton__report" />
              <FontAwesomeIcon
                className="text-primary h-4"
                icon={faChevronRight}
              />
            </Button>
          </div>
        )}
        {channel?.otherMember?.is_angel && (
          <div style={{ backgroundImage: `url(${angelIMG})` }} className=" bg-no-repeat bg-contain bg-left bg-tabs mx-4 my-4 px-4 pl-40 text-sm text-center py-2 rounded-md">
            <div className="message-container me">
              <div className="inner-message">
                <div className="angeltitle">
                  <FormattedMessage
                    values={{
                      member: channel.otherMember!.pseudonym,
                      email: user.informations!.email,
                      message_cost: channel?.angel_message_tokens_cost,
                    }}
                    id="chatangel__description"
                  />
                </div>
                <div className="bg-primary w-fit mx-auto px-2 py-1 rounded-md mt-2">
                  <FormattedMessage
                    values={{
                      member: channel.otherMember!.pseudonym,
                      email: user.informations!.email,
                      message_cost: channel?.angel_message_tokens_cost,
                    }}
                    id="chatangel__cost"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {messages.length === 0 && (
          <div className="text-couple text-center mx-auto w-2/3 py-2">
            <FormattedMessage id="livelist__start_conversation" />
          </div>
        )}
        <div
          id={"scroller-bottom-" + channel?.id}
          className={
            "flex-1 relative overflow-y-scroll scroll--numbers p-2 flex flex-col gap-2 pt-12 lg:mb-0 " +
            (channel?.type === "private" ? " pb-20" : "")
          }
        >
          {xhr && <UploadingBar xhr={xhr} />}

          {messages.map((e: socketMessage) => {
            switch (e.type) {
              case "text":
                return (
                  <TextMessage
                    openDialog={(member: any) => {
                      setDialog(member);
                    }}
                    key={e.time_sent.toString()}
                    message={e as sockedTextMessage}
                  />
                );
              case "content":
                return (
                  <ContentMessage
                    key={e.time_sent.toString()}
                    message={e as sockedContentMessage}
                  />
                );
              case "content_pending": {
                if (e === messages[messages.length - 1]) return <Loader />;
                return null;
              }
              case "gift":
                return (
                  <GiftMessage
                    otherMember={channel?.otherMember}
                    key={e.time_sent.toString()}
                    message={e as sockedGiftMessage}
                  />
                );
              case "tip":
                return (
                  <TipMessage
                    otherMember={channel?.otherMember}
                    key={e.time_sent.toString()}
                    message={e as sockedTipMessage}
                  />
                );
              default:
                return null;
            }
          })}
          {simulated && (
            <SimulatedGift simulated={simulated} setSimulated={setSimulated} />
          )}
          {tokenSimulated && (
            <SimulatedToken
              simulated={tokenSimulated}
              setSimulated={setTokenSimulated}
            />
          )}
          {guestErrorMessage && <GuestMessage />}
          {error && (
            <ErrorMessage member={channel?.otherMember} error={error} />
          )}
        </div>
        <div className=" bg-popinbg w-full flex flex-col items-center chat-textbar">
          {Number.isInteger(channel?.nb_remaining_messages) &&
            (channel as any).nb_remaining_messages > -1 &&
            !user.informations?.is_subscribed && (
              <div className="text-tabs py-2 text-sm border-b border-tabs border-opacity-30 w-full text-center [&>span]:text-primary flex justify-center items-center gap-2">
                <FormattedMessage
                  id={
                    (channel as any).nb_remaining_messages > 1
                      ? "chat__count_messages_left_plural"
                      : "chat__count_messages_left_singular"
                  }
                  values={{ count: channel?.nb_remaining_messages }}
                />
                <FontAwesomeIcon
                  className="text-primary text-xs"
                  icon={faPaperPlane}
                />
              </div>
            )}
          <div className="w-full flex items-center gap-4 p-4">
            {channel?.type === "private" && (
              <div
                className=" text-4xl text-woman"
                onClick={() => setOpenedAddMenu((prec) => !prec)}
              >
                +
              </div>
            )}
            <div className="flex-1 relative">
              <div data-replicated-value={text} className="after:whitespace-pre-wrap after:invisible content-data-replicated grid">
                <textarea
                  value={text}
                  onChange={(event) => setText(event.target.value)}
                  onKeyDown={e => {
                    if (e.key === "Enter" && text.length > 0) {
                      sendMessage();
                    }
                  }}
                  className="rounded-xl w-full bg-message py-3 px-4 pr-14 !text-messagetxt h-full resize-none overflow-hidden"
                  rows={1}
                  placeholder={intl.formatMessage({ id: "textbar__placeholder" })}
                ></textarea>
              </div>
              <input
                ref={fileInputRef}
                onChange={(event) => {
                  if (!event.target.files || !channel) return;
                  sendFile(event.target.files[0]);
                }}
                className="hidden"
                type="file"
                accept="video/*,image/*"
              />
              <div
                onClick={sendMessage}
                className="absolute top-1/2 right-2 -translate-y-1/2 rounded-full h-9 w-9 flex items-center justify-center bg-chatheader"
              >
                <FontAwesomeIcon icon={faPaperPlane} />
              </div>
            </div>
          </div>
          {subError && (
            <SubError
              pseudo={channel?.otherMember?.pseudonym as string}
              error={subError}
              close={() => setSubscriptionError(false)}
            />
          )}
          {openedAddMenu && (
            <div className="flex justify-around w-full border-t p-4">
              <button
                type="button"
                className="flex items-center justify-center flex-col gap-2"
                onClick={() => setOpenedGiftMenu(true)}
              >
                <div className="w-20 h-20 rounded-full flex justify-center items-center bg-message">
                  <FontAwesomeIcon className="w-8 h-8" icon={faGift} />
                </div>
                <div>
                  <FormattedMessage id="chat__gift_send" />
                </div>
              </button>
              {channel?.otherMember?.is_angel && (
                <button
                  type="button"
                  className="flex items-center justify-center flex-col gap-2"
                  onClick={() => setOpenedTokenMenu(true)}
                >
                  <div className="w-20 h-20 rounded-full flex justify-center items-center bg-message">
                    <FontAwesomeIcon className="w-8 h-8" icon={faCoins} />
                  </div>
                  <div>
                    <FormattedMessage id="chat__tokens_send" />
                  </div>
                </button>
              )}
              <button
                type="button"
                className="flex items-center justify-center flex-col gap-2"
              >
                <div
                  onClick={() => fileInputRef.current?.click()}
                  className="w-20 h-20 rounded-full flex justify-center items-center bg-message"
                >
                  <FontAwesomeIcon className="w-8 h-8" icon={faCamera} />
                </div>
                <div>
                  <FormattedMessage id="chat__media_send" />
                </div>
              </button>
            </div>
          )}
        </div>
        {openedGiftMenu && (
          <GiftBar
            close={() => {
              setOpenedGiftMenu(false);
              setOpenedAddMenu(false);
            }}
            channel={channel!}
            member={channel?.otherMember}
            setSimulated={setSimulated}
          />
        )}
        {openedTokenMenu && (
          <TokenBar
            close={() => setOpenedTokenMenu(false)}
            channel={channel!}
            member={channel?.otherMember}
            setSimulated={setTokenSimulated}
          />
        )}
      </div>
    </FileDropArea>
  );
}

export default ChatItem;
